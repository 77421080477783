*,
::after,
::before {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

:root {
  --background: #fff;
  --text-primary: rgba(92, 94, 100, 1);
  --text-secondary: rgba(0, 0, 0, 1);
  --highlighting-background-1: rgba(70, 117, 199, 0.57);
  --highlighting-background: rgba(246, 246, 246, 1);
  --background-shade-1: rgba(250, 250, 250, 1);
  --right-bg: rgb(230, 227, 227);
  --error-bg: rgba(239, 35, 60, 1);
}

html {
  font-size: 62.5%;
}

body {
  width: 100%;
  height: 100vh;
  overflow: auto;
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  width: 0.3rem;
}

::-webkit-scrollbar-thumb {
  background: var(--text-primary);
  border-radius: 2rem;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--text-primary);
}

/* Horizontal scrollbar */
::-webkit-scrollbar:horizontal {
  height: 0.3rem;
}

::-webkit-scrollbar-thumb:horizontal {
  background: var(--text-primary);
  border-radius: 2rem;
}

::-webkit-scrollbar-thumb:hover:horizontal {
  background: var(--text-primary);
}

#root {
  width: 100%;
  height: 100%;
}

[data-theme="dark"] {
  --background: #161a23;
  --text-primary: rgba(138, 140, 145, 1);
  /* --text-secondary: rgba(192, 192, 192, 1); */
  --text-secondary: rgb(238, 238, 238);
  --highlighting-background: rgba(45, 47, 57, 1);
  --background-shade-1: #2d2f39;
  --right-bg: rgba(32, 33, 35, 1);
  --error-bg: rgba(239, 35, 60, 1);
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.reply_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.user_icon {
  margin-right: 2rem;
  display: flex;
  align-items: center;
}

.user_icon > svg {
  width: 2rem;
  height: 2rem;
  color: var(--text-secondary);
}

.chat-table {
  table {
    border-spacing: 0;
  }
  td,
  th {
    border: 0.1rem solid gray;
    padding: 0.5rem;
  }
  th {
    background: #def;
  }
}
